import React from "react"
import { Link, graphql } from "gatsby"

import Breadcrumb from "../components/layout/breadcrumb"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const PageLink = ({ path, text }) => <li><a href={`..${path}`}>{text}</a></li>

const procManufacturers = (proc, item, data, tree, dict) => {
  item.text = 'Manufacturers';
  tree.push(item);
  const { nodes } = data.allManufacturer;
  for (let i = 0; i < nodes.length; i++) {
    const { name, slug } = nodes[i];
    item.children.push({ children: [], path: `/manufacturer${slug}`, text: name });
  }
};

const procEquipment = (proc, item, data, tree, dict) => {
  if (proc.prefix === item.path) {
    tree.push(item);
    dict[item.path] = item;
    return;
  }
  dict[proc.prefix].children.push(item);
  const childPrefix = item.path.replace(/^\/buy-equipment\//, '/').replace(/\/$/, '');
  const { nodes } = data.allCategory;
  for (let i = 0; i < nodes.length; i++) {
    const o = nodes[i];
    if (o.parentCategorySlug === childPrefix) {
      item.children.push({ path: `/catalog${o.slug}`, text: o.name, children: [] });
    }
  }
};

const procCatalog = (proc, item, data, tree, dict) => {
  item.text = 'New Arrivals';
  item.path += 'new-arrivals';
  tree.push(item);
  dict[item.path] = item;

  item.text = 'Featured';
  item.path += 'featured';
  tree.push(item);
  dict[item.path] = item;
};

const processing = [
  { prefix: '/404.html', skip: true },
  { prefix: '/404/', skip: true },
  { prefix: '/auction/', skip: true },
  { prefix: '/browse-manufacturers/', fun: procManufacturers },
  { prefix: '/buy-equipment/', fun: procEquipment },
  { prefix: '/catalog/', fun: procCatalog },
  { prefix: '/contact-us/', skip: true },
  { prefix: '/email-sign-up/', skip: true },
  { prefix: '/dev-404-page/', skip: true },
  { prefix: '/manufacturer/', skip: true },
  { prefix: '/company-news/', skip: true },
  { prefix: '/our-services/', skip: true },
  { prefix: '/product/', skip: true },
  { prefix: '/search3/', skip: true },
  { prefix: '/sitemap/', skip: true },
];

const buildTree = data => {
  const tree = [];
  const dict = {};
  const pages = data.allSitePage.edges;
  for (let i = 0; i < pages.length; i++) {
    const { path } = pages[i].node;
    if (path === '/') {
      continue;
    }
    const proc = processing.find(def => path.startsWith(def.prefix));
    if (proc && proc.skip) {
      continue;
    }
    const parts = path.split('/');
    let lastPath = parts[parts.length - 1];
    if (!lastPath) {
      lastPath = parts[parts.length - 2];
    }
    const text = lastPath.replace(/-/g, ' ', ).replace(/\b\w/g, c => c.toUpperCase());
    const item = { path, text, children: [] };
    if (proc && proc.fun) {
      proc.fun(proc, item, data, tree, dict);
    } else {
      tree.push(item);
      dict[item.path] = item;
    }
  }
  tree.sort((a, b) => a.text.localeCompare(b.text));
  return tree;
}

const SiteMap = ({ data }) => (
  <Layout>
    <SEO title="Sitemap" />

    <Breadcrumb paths={[ {name : "Sitemap", path: "sitemap"}]} />

    <div className="row column">
      <h1>SiteMap</h1>
      <ul>
        <PageLink text="Home" path="/" />
        <ul>
          {buildTree(data).map(o => (
            <>
              <PageLink path={o.path} text={o.text} />
              {o.children.length > 0 && (
                  <ul>
                    {o.children.map(child => (
                      <>
                        <PageLink key={child.path} path={child.path} text={child.text} />
                        {child.children.length > 0 && (
                            <ul>
                              {child.children.map(node => (
                                <PageLink key={node.path} path={node.path} text={node.text} />
                              ))}
                            </ul>
                          )}
                      </>
                    ))}
                  </ul>
                )}
            </>
          ))}
        </ul>
      </ul>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
    allCategory(sort: { fields: [name], order: ASC }) {
      nodes {
        parentCategorySlug
        slug
        name
      }
    }
    allManufacturer(
      sort: {
        fields: [name]
        order: ASC
      }
    ) {
      nodes {
        name
        slug
      } 
    }
  }
`

export default SiteMap

